import { Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { RootState } from '../store';
import Logo from './Logo';
import MainNavigation from './MainNavigation';
import Painting from './Painting';
import VirtualHelp from './VirtualHelp';
import VirtualInfo from './VirtualInfo';

export default function Site() {
    const nav = useSelector((state: RootState) => state.navigation.value);
    const fixMargin = useMediaQuery("(max-width:1150px)");
    const scaleMargin = useMediaQuery("(max-width:800px)");
    const isMobile = useMediaQuery("(max-width:660px)");
    let marginLeft = "calc(40% - 385px)";
    if (fixMargin) marginLeft = "75px";
    if (scaleMargin) marginLeft = "calc(35% - 210px)";
    let w = 1172;
    let h = w / 1.80;
    const displayVT = (nav === "virtualtour") ? "inline" : "none";
    
    const breakPoint = 1600;
    let isSmall1 = useMediaQuery("(max-width:" + (breakPoint) + "px)");
    let isSmall2 = useMediaQuery("(max-width:" + (0.9 * (breakPoint)) + "px)");
    let isSmall3 = useMediaQuery("(max-width:" + (0.8 * (breakPoint)) + "px)");
    let isSmall4 = useMediaQuery("(max-width:" + (0.7 * (breakPoint)) + "px)");
    let isSmall5 = useMediaQuery("(max-width:" + (0.6 * (breakPoint)) + "px)");
    let scale = 1.0;
    let fScale = 1.0;
    if (isSmall1) {
        scale = 0.9;
        fScale = 0.9;
    }
    if (isSmall2) {
        scale = 0.8;
        fScale = 0.81;
    }
    if (isSmall3) {
        scale = 0.7;
        fScale = 0.72;
    }
    if (isSmall4) {
        scale = 0.6;
        fScale = 0.63;
    }
    if (isSmall5) {
        scale = 0.5;
        fScale = 0.54;
    }

    w = w * scale;
    h = h * scale;

    return (
        <Box>
            {!isMobile &&
                <Box sx={{ minHeight: window.innerHeight, bgcolor: "kr.main" }}>
                    <Box>
                        <Logo />
                        <Box sx={{ marginLeft: marginLeft, maxWidth: "62%" }}>
                            <MainNavigation />
                            {nav === "virtualtour" &&
                                <div>
                                    <Painting name="Zirkus4" hidden={true} fromTour={true}></Painting>
                                    <Painting name="Zirkus2" hidden={true} fromTour={true}></Painting>
                                    <Painting name="Papagena" hidden={true} fromTour={true}></Painting>
                                    <Painting name="Hamlet" hidden={true} fromTour={true}></Painting>
                                    <VirtualHelp></VirtualHelp>
                                    <VirtualInfo></VirtualInfo>
                                </div>
                            }
                            
                            <div id="krpano-kr" style={{ width: w, height: h, display: "none" }}></div>
                        </Box>
                        <Box sx={{ marginLeft: marginLeft, maxWidth: "62%" }}>
                            <Outlet></Outlet>
                        </Box>
                    </Box>
                </Box>
            }
            {isMobile &&
                <Box sx={{ minHeight: window.innerHeight, bgcolor: "kr.main" }}>
                    <Logo />
                    <MainNavigation />
                    {nav === "virtualtour" &&
                        <div>
                            <Painting name="Zirkus4" hidden={true} fromTour={true}></Painting>
                            <Painting name="Zirkus2" hidden={true} fromTour={true}></Painting>
                            <Painting name="Papagena" hidden={true} fromTour={true}></Painting>
                            <Painting name="Hamlet" hidden={true} fromTour={true}></Painting>
                            <VirtualHelp></VirtualHelp>
                            <VirtualInfo></VirtualInfo>
                        </div>
                    }
                    <div id="krpano-kr-mobile" style={{ width: window.innerWidth, height: window.innerHeight - 300, display: "none" }}></div>
                    <Outlet></Outlet>
                </Box>
            }
        </Box>
    );
}