/**
 * Contains all common and language-dependent text elements of work details and language-dependent UI text elements 
 * Common elements are stored as top-level properties, language-dependent elements are stored below the respective
 * language property.
 */
export let i18n_messages: { "name": string, "title": string }[];
export let workdetails: { "name": string, "serie": string, "format": string, "hasVideo": string }[];

/** 
 * Loads common and language-dependent elements from files whose names are obtained by adding postfixes to filename.
 * The languages available are given in locals.
*/
export function load_messages(filename: string, locals: string[]) {
    return new Promise((resolve, reject) => {
        // Load common elements
        fetch(filename + '_common.json')
            .then(response => response.json())
            .then(data => {
                workdetails = data;
            });

        // Load language-dependent elements
        locals.forEach((item, idx) => {
            fetch(filename + '_' + item + '.json')
                .then(response => response.json())
                .then(data => {
                    i18n_messages = data;
                    if (idx == locals.length-1) resolve("");
                });
        }
        );
   });

}

export interface Workdesc { "name": string, "serie": string, "format": string, "hasVideo": string, "title": string };

/**
 * Returns common and/or language-dependent text elements
 */
export default function i18n(key: string, locale: string): Workdesc {
    let y: Workdesc;
    for (let x of workdetails) {
        if (x.name === key) {
            y = { "name": x.name, "serie": x.serie, "format": x.format, "hasVideo": x.hasVideo, "title": "" };
        }
    };
    for (let z of i18n_messages) {
        if (z.name === key) {
            y!.title = z.title;
        }
    };
    return y!;
}

export function getSeries(serie: string): { name: string, title: string, image: string, format: string }[] {
    let itemData: { name: string, title: string, image: string, format: string }[] = [];
    for (let x of workdetails) {
        if (("Serie_" + x.serie) === serie) {
            const y = i18n(x.name, "DE");
            itemData.push({ ...y, image: "small/" + x.name });
        }
    };

    return itemData;
}

