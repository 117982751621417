import { Box, ButtonBase } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';

export default function Logo() {
   return (
      <Box sx={{ display: 'flex', justifyContent: 'end', bgcolor: "kr.main", paddingTop: '40px', paddingRight: '40px' }} >
            <ButtonBase component={RouterLink} to="/">
                <img src="/img/logo.png" style={{ height: "150px"}} />
            </ButtonBase>
      </Box>
   );
}