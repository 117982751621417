import { Box, Fade, Paper, useMediaQuery } from "@mui/material";
import { useDispatch } from "react-redux";
import { change } from "../state/Nav";
import { useEffect, useState } from "react";
import { MediaQueryMobile } from "../Styles";
import { hideKrpano } from "../Util";

export default function Home() {
    const [count, setCount] = useState(0);
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(MediaQueryMobile);

    const imgWidth = isMobile ? "90%" : "80%";
    const paddingTop = isMobile ? "10%" : "30px";
    hideKrpano();

    useEffect( () => {
        const tick= setInterval(() => {setCount(count+1)}, 3000);
        return ()=>{
            clearInterval(tick);
          }
    });

    if (count === 0) {
         dispatch(change("home"));
    }
    
    const imgNr = ((count % 10) + 1).toString();
    return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "30px", paddingTop: paddingTop }}>
            <img src={"/img/large/kr" + imgNr + ".png"} style={{ marginLeft: "auto", marginRight: "auto", width: imgWidth}} />
        </Box>
    );
}