import { Box, useMediaQuery } from "@mui/material";
import MainNavigationItem from "./MainNavigationItem";
import MainNavigationMobile from "./MainNavigationMobile";
import { MediaQueryMobile } from "../Styles";

export default function MainNavigation() {
    const isMobile = useMediaQuery(MediaQueryMobile);

    return (
        <div>
            {!isMobile &&
                <Box sx={{ paddingBottom: "3.75vH" }}>
                    <MainNavigationItem name="Über der Kunstraum" path="about" />
                    <MainNavigationItem name="Zur Künstlerin" path="artist" />
                    <MainNavigationItem name="Über das Werk" path="art" />
                    <MainNavigationItem name="Online-Galerie" path="gallery/all" />
                    <MainNavigationItem name="Virtuelle Tour" path="virtualtour" />
                    <MainNavigationItem name="Besuch/Kontakt" path="visit" />
                    {false &&
                        <div>
                            <MainNavigationItem name="Künstlerbücher" path="" />
                            <MainNavigationItem name="Shop" path="shop" />
                        </div>
                    }
                </Box>
            }
            {isMobile &&
                <MainNavigationMobile />
            }
        </div>
    );
}