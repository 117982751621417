import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Site from './component/Site';
import { changeLang } from './state/Language';
import { RootState } from './store';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import About from './component/About';
import Artist from './component/Artist';
import Gallery from './component/Gallery';
import Home from './component/Home';
import PaintingViaURL from './component/PaintingViaURL';
import Shop from './component/Shop';
import Test from './component/Test';
import VirtualTour from './component/VirtualTour';
import Visit from './component/Visit';
import { i18n_messages, load_messages, workdetails } from './i18n';
import Art from './component/Art';

declare module '@mui/material/styles' {
  interface Palette {
    kr: Palette['primary'];
    active: string;
  }

  interface PaletteOptions {
    kr?: PaletteOptions['primary'];
  }

  interface Theme {
    painting: {
      landscape: {
        height: number,
        width: number,
      },
      portrait: {
        height: number,
        width: number,
      },
      title: {
        p: string,
      },
      p: string,
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    painting?: {
      landscape?: {
        height?: number;
        width?: number,
      },
      portrait?: {
        height?: number;
        width?: number,
      },
      title: {
        p: string,
      },
      p?: string,
    };
  }
}
declare global {
  interface Window { workdetails: any; messages: any}
}

function App() {
  const [loaded, setLoaded] = useState(false);
  const lang = useSelector((state: RootState) => state.language.value);
  const dispatch = useDispatch();
  dispatch(changeLang("EN"));

  useEffect(() => {
    load_messages("/data/workdetails", ["de"]).then(() => {
      window.workdetails  = workdetails;
      window.messages = i18n_messages;
      setLoaded(true);
    }
    );
  });

  const theme = createTheme({
    palette: {
      kr: {
        main: "#f4f2e8",
      }
    },
    painting: {
      landscape: {
        width: 853,
        height: 607,
      },
      portrait: {
        width: 607,
        height: 853,
      },
      title: {
        p: "0px"
      },
      p: "20px"
    }
  });

  return (
    <ThemeProvider theme={theme} key={Math.floor(Math.random() * 1000)}>
      {loaded &&
        <Routes>
          <Route path="/" element={<Site />}>
            <Route path="" element={<Home />} />
            <Route path="home" element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="artist" element={<Artist />} />
            <Route path="art" element={<Art />} />
            <Route path="visit" element={<Visit />} />
            <Route path="gallery/:serie" element={<Gallery />} />
            <Route path="virtualtour" element={<VirtualTour />} />
            <Route path="shop" element={<Shop />} />
            <Route path="paintings/:name" element={<PaintingViaURL />}></Route>
          </Route>
          <Route path="test" element={<Test />} />
        </Routes>
      }
    </ThemeProvider>
  );
  //  return <>{routing}</>;
}

export default App;
