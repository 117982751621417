
import { useParams } from 'react-router-dom';
import Painting from './Painting';

export default function PaintingViaURL() {
    const params = useParams();
 
    return (
        <Painting name={params.name!} fromTour={false}></Painting>
    );
}


