import { createSlice } from '@reduxjs/toolkit'

export const gallerySlice = createSlice({
    name: 'gallery',
    initialState: {
        sizes: [ 355, 755, 1155]
    },
    reducers: {
        changeSizes: (state, action) => {
            state.sizes = action.payload
        }
    }
})

export const { changeSizes } = gallerySlice.actions

export default gallerySlice.reducer