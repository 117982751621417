import { Box, Typography, useMediaQuery } from "@mui/material";
import { useDispatch } from "react-redux";
import { change } from "../state/Nav";
import { TextBody, TextBox, TextHeader } from "../Styles";
import { hideKrpano } from "../Util";

export default function About() {
    const dispatch = useDispatch();
    dispatch(change("about"));
    hideKrpano();
     
    return (
        <TextBox>
            <TextHeader>
                Über den Kunstraum
            </TextHeader>
            <TextBody>
                Der Kunstraum Rudolfsplatz vermittelt im Rahmen einer begleiteten Dauerausstellung in historischen Räumlichkeiten am Rudolfsplatz in Wien
                das über mehr als zwei Jahrzehnte entstandene Œuvre der russisch-österreichischen bildenden Künstlerin Anna Laour.
                Durch den ergänzenden und unterstützenden Einsatz von Techniken der Kunstvermittlung, wie sie sonst nur im musealen Bereich anzutreffen sind,
                werden die Werke der Künstlerin in besonderer Weise erlebbar und erfahrbar gemacht.
            </TextBody>
            <TextHeader marginTop="20px">
                Zum Ausstellungsort
            </TextHeader>
            <TextBody>
                Der Kunstraum Rudolfsplatz ist im gründerzeitlichen Gebäude Rudolfsplatz 3 beheimatet, das vom Architekten Anton Baumgarten ab dem Jahre 1861 erbaut wurde.
                Das Gebäude gehört zu einer Reihe imposanter Zinshäuser, die den ca. vier Tausend Quadratmeter großen Rudolfspark säumen.
                Der Park, eine grüne Oase inmitten des nördlichen Teils der Wiener Innenstadt, bildet das Zentrum und bedeckt einen Großteil des gleichnamigen Platzes.             </TextBody>
            <TextBody marginTop="10px">
                Das Areal des heutigen, 1862 nach Kronprinz Rudolf benannten Rudolfsplatzes war ursprünglich von einem der Donauarme, dem sog. „Salzgriesarm“, bedeckt. Durch die zunehmende Versandung des Flusses konnte im Mittelalter auf dem so gewonnenen Land die unmittelbar außerhalb der Stadtmauern gelegene Siedlung „Vorstadt vor dem Werdertor“ gegründet werden. Nachdem der Salzgriesarm endgültig seine Schiffbarkeit verloren hatte, wurde er zugeschüttet, und auf dem Gelände entstanden ab dem 16. Jh. neuen Festungsanlagen sowie das davor liegende Glacis. Im Zuge der Stadterweiterung des 19. Jh. wurde das veraltete Verteidigungssystem 1857 geschliffen, und die nachfolgende gründerzeitliche Bebauung gab dem Rudolfsplatz und seiner Umgebung im Wesentlichen sein heutiges Aussehen. Die noch immer gebräuchliche Bezeichnung „Textilviertel“ für diesen Bereich der Wiener Innenstadt rührt von den zahlreichen, oft jüdischen Stoff- und Kleidungshändler her, die sich um die Jahrhundertwende hier ansiedelten.            </TextBody>
        </TextBox>
    );
}