import { Close } from "@mui/icons-material";
import { Box, Dialog, IconButton } from "@mui/material";
import { useState } from "react";
import { TextBody, TextHeader } from "../Styles";

export default function VirtualInfo() {
   const [hidden, setHidden] = useState(true);

   const modalDisplay = (hidden) ? "none" : "flex";
   const w = Math.min(500, window.innerWidth - 80);

   return (
      <Dialog id="virtual-info" open={true} sx={{ display: modalDisplay, alignItems: "center", justifyContent: "center" }}>
         <Box sx={{ width: w, borderRadius: 3, bgcolor: 'kr.main', padding: "20px" }}>
            <TextHeader>
               Bedienerhinweise
            </TextHeader>
            <TextBody marginTop="10px">
               Um innerhalb der virtuellen Ausstellung zu navigieren, benützen Sie bitte die Symbollleiste unten:
               <table id="help-controlbar">
                  <tr>
                     <td style={{ padding: "5px" }}><img style={{ display: "inline", width: "20px", height: "20px" }} src="icon/PrevScene.png" /></td>
                     <td style={{ width: "40%" }}>zum vorherigen Raum</td>
                     <td style={{ padding: "5px" }}><img style={{ display: "inline", width: "20px", height: "20px" }} src="icon/NextScene.png" /></td>
                     <td style={{ width: "40%" }}>zum nächsten Raum</td>
                  </tr>
                  <tr>
                     <td style={{ padding: "5px" }}><img style={{ display: "inline", width: "20px", height: "20px" }} src="icon/RotateLeft.png" /></td>
                     <td>nach links drehen</td>
                     <td style={{ padding: "5px" }}><img style={{ display: "inline", width: "20px", height: "20px" }} src="icon/RotateRight.png" /></td>
                     <td>nach rechts drehen</td>
                  </tr>
                  <tr>
                     <td style={{ padding: "5px" }}><img style={{ display: "inline", width: "20px", height: "20px" }} src="icon/RotateUp.png" /></td>
                     <td>nach oben drehen</td>
                     <td style={{ padding: "5px" }}><img style={{ display: "inline", width: "20px", height: "20px" }} src="icon/RotateDown.png" /></td>
                     <td>nach unten drehen</td>
                  </tr>
                  <tr>
                     <td style={{ padding: "5px" }}><img style={{ display: "inline", width: "20px", height: "20px" }} src="icon/MoveForward.png" /></td>
                     <td>vorwärts bewegen (vergrößern)</td>
                     <td style={{ padding: "5px" }}><img style={{ display: "inline", width: "20px", height: "20px" }} src="icon/MoveBackward.png" /></td>
                     <td>rückwärts bewegen (verkleinern)</td>
                  </tr>
                  <tr>
                     <td style={{ padding: "5px" }}><img style={{ display: "inline", width: "20px", height: "20px" }} src="icon/Fullscreen.png" /></td>
                     <td>Vollbildmodus beginnen</td>
                     <td style={{ padding: "5px" }}><img style={{ display: "inline", width: "20px", height: "20px" }} src="icon/EndFullscreen.png" /></td>
                     <td>Vollbildmodus beenden</td>
                  </tr>
                  <tr>
                     <td style={{ padding: "5px" }}><img style={{ display: "inline", width: "20px", height: "20px" }} src="icon/HideControlBar.png" /></td>
                     <td>Symbolleiste ausblenden</td>
                     <td style={{ padding: "5px" }}><img style={{ display: "inline", width: "20px", height: "20px" }} src="icon/ShowControlBar.png" /></td>
                     <td>Symbolleiste einblenden</td>
                  </tr>
                  <tr>
                     <td style={{ padding: "5px" }}><img style={{ display: "inline", width: "20px", height: "20px" }} src="icon/Thumbnails.png" /></td>
                     <td>navigierbare Miniaturbilder anzeigen/ausblenden</td>
                     <td style={{ padding: "5px" }}><img style={{ display: "inline", width: "20px", height: "20px" }} src="icon/help1.png" /></td>
                     <td>diese Hilfe anzeigen</td>
                  </tr>
               </table>
               <p></p>
               Sie können auch das Scroll-Rad der Maus für die Vergrößerung/-kleinerung der Panoramen verwenden sowie durch Klicken und Ziehen der Panoramen diese drehen.
               <p>Beachten Sie auch die anklickbaren Türen, die Sie in die angrenzenden Räume führen.</p>
            </TextBody>
            <IconButton sx={{ position: "absolute", bottom: "10px", right: "10px" }} size="small" onClick={() => {
               if (hidden) {
                  const s = document.getElementById("virtual-info") as HTMLDivElement;
                  s.style.display = "none";
               }
               else {
                  setHidden(true)
               }
            }}>
               <Close></Close>
            </IconButton>
         </Box>
      </Dialog >
   );
}