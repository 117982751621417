// @ts-nocheck
import { useMediaQuery } from "@mui/material";
import { useDispatch } from "react-redux";
import { change } from "../state/Nav";
import { MediaQueryMobile } from "../Styles";

export default function VirtualTour() {
    const dispatch = useDispatch();
    dispatch(change("virtualtour"));

    const isMobile = useMediaQuery(MediaQueryMobile);

    const tgtid = (!isMobile) ? "krpano-kr" : "krpano-kr-mobile";
    const tgt = document.getElementById(tgtid);

    if (tgt) {
        tgt.style.display = "block";
        if (!window.krpanoelem) {
            embedpano({
                xml: "virtualtour.xml", onready: (krpano) => {
                    window.krpano = krpano;
                    //x.requestFullscreen();
                }, id: "mypano" + Math.floor(Math.random() * 1000).toString(), target: tgtid, html5: "only", mobilescale: 1.0, passQueryParameters: true, consolelog: true
            });
            window.krpanoelem = tgt;
            window.krpanomobile = isMobile;
            initKrpano();
        }
    }

    return (
        <>
        </>
    );
}