import { Box, Typography } from "@mui/material";

export default function PaintingTitle(params: { title: string, idPrefix: string }) {

    return (
        <Box component="div" sx={{ paddingTop: "10px", display: "flex", alignItems: "bottom", justifyContent: "center" }}>
            <Typography id={params.idPrefix+"-title"} gutterBottom variant="subtitle1" >
            {params.title}
            </Typography>
        </Box>
    );
}