import { configureStore } from '@reduxjs/toolkit'
import langReducer from "./state/Language"
import navReducer from "./state/Nav"
import gallerySizeReducer from './state/GallerySize'
import videoReducer from './state/VideoParams'

const store = configureStore({
  reducer: {
    language: langReducer,
    navigation: navReducer,
    gallery: gallerySizeReducer,
    video: videoReducer
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;