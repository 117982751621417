import { createSlice } from '@reduxjs/toolkit'

export const videoSlice = createSlice({
    name: 'currentTime',
    initialState: {
        value: 0
    },
    reducers: {
        changeCurrentTime: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { changeCurrentTime } = videoSlice.actions

export default videoSlice.reducer