import { Box, Button, MenuItem, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { RootState } from "../store";
import { change } from "../state/Nav";

export default function MainNavigationItemMobile(params: { name: string, path: string, callback: () => void }) {
    const nav = useSelector((state: RootState) => state.navigation.value);

    const active = (params.path.startsWith(nav));
    let col = "black";
    if (active) col = "#b7979c";
     return (
        <MenuItem dense onClick={params.callback} component={RouterLink} to={"/" + params.path} 
        sx={{ ':hover': { bgcolor: 'kr.main'}, color: col, margin: "0px", textTransform: 'none' }}>
            <Typography variant="subtitle1" sx={{margin: 0, padding:0}}>
                {params.name}
            </Typography>
        </MenuItem>
    );
}