import { Box, Typography, TypographyProps, useMediaQuery } from "@mui/material";
import { ReactElement, JSXElementConstructor, ReactNode, ReactPortal } from "react";
import styled from "styled-components";

export const MediaQueryMobile = "(max-width:660px)";

export const TextBox = (props: { children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; }) => {
    const isMobile = useMediaQuery(MediaQueryMobile);

    const maxWidthBox = isMobile ? "80%" : "600px";
    const paddingTopBox = isMobile ? "0px" : "25px";
    const paddingLeftBox = isMobile ? "30px" : "0px";

    return (
        <Box sx={{ maxWidth: maxWidthBox, paddingTop: paddingTopBox, paddingLeft: paddingLeftBox }} >
            {props.children}
        </Box>
    );
}

export const TextHeader = (props: { marginTop?: string, children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; }) =>
(<div>
    {
        props.marginTop &&
        <Typography variant="h5" sx={{ fontSize: "1.7rem", marginTop: props.marginTop }}>
            {props.children}
        </Typography>
    }
    {
        !props.marginTop &&
        <Typography variant="h5" sx={{ fontSize: "1.7rem" }}>
            {props.children}
        </Typography>
    }
</div>
)

export const TextBody = (props: { marginTop?: string, children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; }) =>
(<div>
    {
        props.marginTop &&
        <Typography variant="body2" sx={{ fontSize: "0.925rem", lineHeight: "1.4rem", marginTop: props.marginTop }}>
            {props.children}
        </Typography>
    }
    {
        !props.marginTop &&
        <Typography variant="body2" sx={{ fontSize: "0.925rem", lineHeight: "1.4rem"}}>
            {props.children}
        </Typography>
    }
</div>
)

