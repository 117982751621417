// @ts-nocheck
import { createPortal } from 'react-dom';
import { AppBar, Box, Button, ButtonBase, Modal, Toolbar, Typography, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";
import Banner from "./Banner";
import Footer from "./Footer";
import Logo from "./Logo";
import MainNavigation from "./MainNavigation";
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { TransformWrapper, TransformComponent, useTransformComponent } from "react-zoom-pan-pinch";
import Video from "./Video";
import { useRef, useState } from "react";
import { Document, Page } from 'react-pdf'
import { pdfjs } from 'react-pdf';
import HTMLFlipBook from "react-pageflip";
import React from "react";
import { Padding } from "@mui/icons-material";
import { Krpano } from "@0xllllh/react-krpano";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

const Pages = React.forwardRef((props, ref) => {
    return (
        <Box ref={ref} sx={{ padding: 0}}>
            <p>{props.children}</p>
        </Box>
    );
});

Pages.displayName = 'Pages';

const h = 831;
const w = 600;

export default function Test() {
    console.log("KRPano");
    return (
        <>
            {createPortal(<p>This child is placed in the document body.</p>,
    document.body)

            }
        </>
    )
}